import React from "react";
import SectionHeader from "./SectionHeader";
import Select from "../util/Select";
import Input from "../util/Input";
import MulSelectCreatable from "../util/MulSelectCreatable";
import { PropTypes } from "prop-types";
import { Radio } from "@nvidia/kaizen-ui";
import ReactTooltip from 'react-tooltip';


const Environment = props => {
  if (props.productFamily && props.productFamily.toUpperCase()==="OMNIVERSE") {
    if(props.isBoxx)
    {
      return  (
        <div className="AppSection">
          <SectionHeader title={"Environment"} />
          <div className={"leftColumn"}>
            <Select
              labelText={"Certified System"}
              optionValues={props.BoxxCertifiedSystemOptions}
              isRequired={true}
              onChange={props.handleCertfiiedSystemChange}
            />
            
            {/* <Select
  
              labelText={"Deployment"}
              isVMWare={false}
              optionValues={props.BoxxDeploymentOptions}
              isRequired={true}
              onChange={props.handleBoxDeployment}
            /> */}
              
              <Select
              labelText={"NVIDIA GPUs"}
              optionValues={props.BoxxNvidiaGPUOptions}
              isRequired={true}
              onChange={props.handleBoxxNvidiaGPUChange}
            />  
             <Select
          labelText={"Deployment Type"}
          optionValues={props.BoxxDeploymentTypeOptions}
          isRequired={true}
          onChange={props.handleBoxDeploymentTypeChange}
                  />
          </div>
        
        
             
        </div>
      );
    }
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"NVIDIA GPUs Deployed"}
            optionValues={props.NvidiaGpusDeployedOptions}
            isRequired={true}
            onChange={props.handleGPUDeployed}
          />
          
          <Select
            labelText={"Deployment"}
            optionValues={props.deploymentOptions}
            isRequired={true}
            onChange={props.handleDeployment}
          />
            
           { <MulSelectCreatable labelText={"Primary Application"}
            optionValues={props.primaryAppOptions}
            isRequired={true}
            onChange={props.handlePrimaryAppChange}></MulSelectCreatable> }
        </div>
        <div className={"leftColumn"}>
        <Select
            labelText={"UseCase"}
            optionValues={props.useCaseOptions}
            isRequired={true}
            onChange={props.handleUseCaseOptions}
          />
          <Select
            labelText={"Estimated Number Of Users"}
            optionValues={props.estimatedUserOptions}
            isRequired={true}
            onChange={props.handleEstimatedUsersOptions}
          />  
    
        
            <div>
       <Input className={'otherMargin'}
          labelText={"Other Applications"}
          isRequired={false}
          value={props.otherApplicationsVal}      
          disabled={false}
          readOnly={false}
          onChange={props.handleOtherValChange}
          maximumLength={160}
        />
        </div>
            </div>
           
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="SPECTRUM-X") {
    return  (
   
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn true"}>
        <Select
          labelText={"GPU Server Vendor"}
          optionValues={props.stmxVendorOptions}
          isRequired={true}
          onChange={props.handleSTMXVendorChange}
        />
             <Select
          labelText={"NVIDIA GPU "}
          optionValues={props.stmxGPUOptions}
          isRequired={true}
          onChange={props.handleNSTMXGPUChange}
        />
           <Select
          labelText={"Switch Model"}
          optionValues={props.stmxModelOptions}
          isRequired={true}
          onChange={props.handleSTMXSwitchModelChange}
        />
     
        </div>
        
        <div className={"leftColumn true"}>
        <Select
          labelText={"No. of Switches in Eval Envs"}
          optionValues={props.stmxNosOptions}
          isRequired={true}
          onChange={props.handleSTMXSwitchNOSChange}
         
        />
        <Select
          labelText={"Switch NOS"}
          optionValues={props.stmxSwitchOptionsUOptions}
          isRequired={true}
          onChange={props.handleSTMXNosSwitchEvalChange}
        />
       
      
             <Input className={'otherMargin'}
          labelText={"NVIDIA Contact"}
          isRequired={false}
          value={props.stmxNvidiaContactVal}      
          disabled={false}
          readOnly={false}
          onChange={props.handleSTMXNvidiaContactChange}
          maximumLength={160}
        />
       
          </div>
          <div  className={"leftColumn true"}>
        <Input className={'otherMargin'}
          labelText={"AI Applications"}
          isRequired={false}
          value={props.stmxAIAppVal}      
          disabled={false}
          readOnly={false}
          onChange={props.handleSTMXAIAppChange}
          maximumLength={160}
        />
        </div>
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="NVAIENTERPRISE") {
    if(props.isSnowflake)
    {
      return  (

        
        <div className="AppSection">
          <SectionHeader title={"Environment"} />
          <div className={"leftColumn true"}>
          <Select
            labelText={"Use Case"}
            optionValues={props.nvaiWorkloadsOptions}
            isRequired={true}
            onChange={props.handleNVAIWorkLoadsChange}
          />
          <Select
            labelText={"Project Size"}
            optionValues={props.nvaiSizeOptions}
            isRequired={true}
            onChange={props.handleNVAIProjectSizeChange}
          />
        
          </div>
          
         
        </div>
      );
    }
    return  (

      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div style={{ display: 'flex' }} className={""}>
        <Select
          labelText={"NVIDIA GPUs"}
          optionValues={props.nvaiGPUOptions}
          isRequired={true}
          onChange={props.handleNVAIGPUChange}
        />
         
         <div  style={{ marginLeft: '50px'}}>
             <Select
          labelText={"Infrastructure "}
          optionValues={props.nvaiInfraOptions}
          isRequired={true}
          onChange={props.handleNVAIInfraChange}
        />
         </div>
        </div>
        
          <div style={{ display: 'flex' }} >
   
       <Select
          labelText={"Use Case"}
          optionValues={props.nvaiWorkloadsOptions}
          isRequired={true}
          onChange={props.handleNVAIWorkLoadsChange}
         
        />
      
      
        
       <div  style={{ marginLeft: '65px'}}>
       <Select
          labelText={"Deployment"}
          optionValues={props.nvaiVirtualOptions}
          isRequired={true}
          onChange={props.handleNVAIDeploymentChange}
        />
     
        </div>
        </div>
        <div style={{ marginLeft: '10px'}}>
       
        <Select
        
        labelText={"Project Size"}
        optionValues={props.nvaiSizeOptions}
        isRequired={true}
        onChange={props.handleNVAIProjectSizeChange}
      />
       </div>
         
        
      </div>
    );
  }
 else if (props.productFamily && props.productFamily.toUpperCase()==="NVAIE") {
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"Compatible Hardware"}
            optionValues={props.CompatibleHardwareOptions}
            isRequired={true}
            onChange={props.handleCompatibleHardwareChange}
          />
          
          <Select

            labelText={"VMware vSphere 7 U2 Enterprise Edition or later"}
            isVMWare={true}
            optionValues={props.VMWareOptions}
            isRequired={true}
            onChange={props.handleVMWareChange}
          />
            
            <Select
            labelText={"Estimated Number Of Users"}
            optionValues={props.estimatedUserOptions}
            isRequired={true}
            onChange={props.handleEstimatedUsersOptions}
          />  
        </div>
        
           
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="UFM") {
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"Deployment Method"}
            optionValues={props.ufmDeploymentMethodOptions}
            isRequired={true}
            onChange={props.handleUFMDeploymentMethodChange}
          />
          
          <Select

            labelText={"Platform"}
            isVMWare={true}
            optionValues={props.ufmPlatformOptions}
            isRequired={true}
            onChange={props.handleUFMPlatformChange}
          />
            
            <Select
            labelText={"Number Of Nodes"}
            optionValues={props.ufmNumberOfNodesOptions}
            isRequired={true}
            onChange={props.handleUFMNumberOfNodesChange}
          />  
        </div>
        
           
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="NETQ") {
   
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"Network operating system"}
            optionValues={props.NETQOSOptions}
            isRequired={true}
            onChange={props.handleNetworkOperatingSystemChange}
          />
           
           <ReactTooltip place="right" data-for="network" type="success" effect="solid"/>
          <Input datatip="Enter monitoring tools currently in use" className={'leftColumn'}
          labelText={"Other network monitoring applications"}
          isRequired={false}
          value={props.otherNetworkElements}      
          disabled={false}
          readOnly={false}
          onChange={props.handleOtherNetworkElementsChange}
          maximumLength={160}
        />
     </div>
    
          {/* <div className={''}>
           <label style = {{  marginLeft : -30 }}>
           Check this box to choose the NetQ SaaS option
           
            <input
             labelText={"Check this box to choose the NetQ SaaS option"}
              type='checkbox'
              checked={props.isNetqSaas}
              onChange={props.handleNetQSaasChange}
            />
     </label>
        </div>  */}
        
        {/* <div className={"leftColumn"}>
       
        
        <Input  datatip="Site/premise name used to logically group switches as an administrative domain" className={'leftColumn'}
          labelText={"Site/Premise Name"}
          isRequired={props.isNetqSaas}
          value={props.isNetqSaas?props.premiseName:''}      
          disabled={!props.isNetqSaas}
          readOnly={!props.isNetqSaas}
          onChange={props.handlePremiseNameChange}
          maximumLength={160}
        />
     
        <Input className={'leftColumn'}
          labelText={"Number Of Switches"}
          isRequired={props.isNetqSaas}
          value={props.isNetqSaas?props.numberOfSwicthes:''}      
          disabled={!props.isNetqSaas}
          readOnly={!props.isNetqSaas}
          onChange={props.handleNumberOfSwicthesChange}
          maximumLength={160}
        />
        </div> */}
        {/* <div style = {{  marginLeft : -48 }} className={''}>
           <span style = {{  marginLeft : -0 }}>
           <br></br>Please provide name and email of user that should receive SaaS portal access.
           <br></br>
           <br></br>
     </span>
        </div>  */}
        {/* <div className={''}>
           <label style = {{  marginLeft : 40 }}>
           Saas portal user, same as above?
           
            <input
             labelText={"Saas portal user, same as above?"}
              type='checkbox'
              checked={props.IsNetQSameUser}
              onChange={props.handleNetqSameUserChange}
              disabled={!props.isNetqSaas }
            />
     </label>
     </div> */}
     {/* <div className={'leftColumn'}>
     <Input className={'leftColumn'}
          labelText={"First Name"}
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          value={props.NetQFirstName}      
          disabled={!props.isNetqSaas || props.IsNetQSameUser}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          onChange={props.handleNetQFirstNameChange}
          maximumLength={100}
        />
        <Input className={'leftColumn'}
          labelText={"Last Name"}
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          value={props.NetQLastName}      
          disabled={!props.isNetqSaas || props.IsNetQSameUser}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          onChange={props.handleNetQLastNameChange}
          maximumLength={100}
        />
        <Input
          labelText='Email Address'
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          onChange={props.handleNetQEmailChange}         
          value={props.NetQEmail}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          maximumLength={100}
          
          
        /> 
        </div>  */}
        
        
         
       
     
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="NETQSAAS") {
   
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"Network operating system"}
            optionValues={props.NETQOSOptions}
            isRequired={true}
            onChange={props.handleNetworkOperatingSystemChange}
          />
           
           <ReactTooltip place="right" data-for="network" type="success" effect="solid"/>
          <Input datatip="Enter monitoring tools currently in use" className={'leftColumn'}
          labelText={"Other network monitoring applications"}
          isRequired={false}
          value={props.otherNetworkElements}      
          disabled={false}
          readOnly={false}
          onChange={props.handleOtherNetworkElementsChange}
          maximumLength={160}
        />
     </div>
    
          {/* <div className={''}>
           <label style = {{  marginLeft : -30 }}>
           Check this box to choose the NetQ SaaS option
           
            <input
             labelText={"Check this box to choose the NetQ SaaS option"}
              type='checkbox'
              checked={props.isNetqSaas}
              onChange={props.handleNetQSaasChange}
            />
     </label>
        </div>  */}
        
        <div className={"leftColumn"} style = {{  display : 'block' }}>
       
        
        <Input  datatip="Site/premise name used to logically group switches as an administrative domain" className={'leftColumn'}
          labelText={"Site/Premise Name"}
          isRequired={true}
          value={props.premiseName}              
          onChange={props.handlePremiseNameChange}
          maximumLength={160}
        />
     
        <Input className={'leftColumn'}
          labelText={"Number Of Switches"}
          isRequired={true}
          value={props.numberOfSwicthes}      
     
          onChange={props.handleNumberOfSwicthesChange}
          maximumLength={160}
        />
        </div>
        {/* <div style = {{  marginLeft : -48 }} className={''}>
           <span style = {{  marginLeft : -0 }}>
           <br></br>Please provide name and email of user that should receive SaaS portal access.
           <br></br>
           <br></br>
     </span>
        </div>  */}
        {/* <div className={''}>
           <label style = {{  marginLeft : 40 }}>
           Saas portal user, same as above?
           
            <input
             labelText={"Saas portal user, same as above?"}
              type='checkbox'
              checked={props.IsNetQSameUser}
              onChange={props.handleNetqSameUserChange}
              disabled={!props.isNetqSaas }
            />
     </label>
     </div> */}
     {/* <div className={'leftColumn'}>
     <Input className={'leftColumn'}
          labelText={"First Name"}
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          value={props.NetQFirstName}      
          disabled={!props.isNetqSaas || props.IsNetQSameUser}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          onChange={props.handleNetQFirstNameChange}
          maximumLength={100}
        />
        <Input className={'leftColumn'}
          labelText={"Last Name"}
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          value={props.NetQLastName}      
          disabled={!props.isNetqSaas || props.IsNetQSameUser}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          onChange={props.handleNetQLastNameChange}
          maximumLength={100}
        />
        <Input
          labelText='Email Address'
          isRequired={!props.IsNetQSameUser&&props.isNetqSaas}
          onChange={props.handleNetQEmailChange}         
          value={props.NetQEmail}
          readOnly={!props.isNetqSaas || props.IsNetQSameUser}
          maximumLength={100}
          
          
        /> 
        </div>  */}
        
        
         
       
     
      </div>
    );
  }
  else if (props.productFamily && props.productFamily.toUpperCase()==="PARTNEROMNIVERSE") {
  
    
    return  (
      <div className="AppSection">
        <SectionHeader title={"Environment"} />
        <div className={"leftColumn"}>
          <Select
            labelText={"Certified System"}
            optionValues={props.PartnerCertifiedOptions}
            isRequired={true}
            onChange={props.handlePartnerCertifiedSystemChange}
          />
          
          <Select

            labelText={"Deployment"}
            isVMWare={false}
            optionValues={props.PartnerDeploymentOptions}
            isRequired={true}
            onChange={props.handlePartnerDeployment}
          />
            
            <Select
            labelText={"NVIDIA GPUs"}
            optionValues={props.PartnerNvidiaGPUOptions}
            isRequired={true}
            onChange={props.handlePartnerNvidiaGPUChange}
          />  
        </div>
        
           
      </div>
    );
  }
 
  return (
    <div className="AppSection">
      <SectionHeader title={"Environment"} />
      <div className={"leftColumn isvgpuClass"}>
        <Select
          labelText={"Certified Server"}
          optionValues={props.serverOptions}
          isRequired={true}
          onChange={props.handleServerChange}
        />
        <Select
          labelText={"VDI Hypervisor"}
          optionValues={props.hypervisorOptions}
          isRequired={true}
          onChange={props.handleHypervisorChange}
        />
        <Select
          labelText={"VDI Seats"}
          optionValues={props.seatOptions}
          isRequired={true}
          onChange={props.handleSeatsChange}
        />
            
            <MulSelectCreatable labelText={"Primary Application"}
          optionValues={props.primaryAppOptions}
          isRequired={true}
          onChange={props.handlePrimaryAppChange}></MulSelectCreatable>
        
      </div>
      <div className={"leftColumn isvgpuClass"}>
        <Select
          labelText={"NVIDIA GPUs"}
          optionValues={props.gpuOptions}
          isRequired={true}
          onChange={props.handleGpuChange}
        />
        <Select
          labelText={"VDI Remoting Client"}
          optionValues={props.remotingOptions}
          isRequired={true}
          onChange={props.handleRemotingChange}
        />
       
       <Input className={'otherMargin'}
          labelText={"Other Applications"}
          isRequired={false}
          value={props.otherApplicationsVal}      
          disabled={false}
          readOnly={false}
          onChange={props.handleOtherValChange}
          maximumLength={160}
        />
         
          </div>
      
    </div>
  );
};

Environment.propTypes = {
  serverOptions: PropTypes.array.isRequired,
  isBoxx:PropTypes.bool,
  isNetqSaas:PropTypes.bool,
  handleNetQSaasChange:PropTypes.func.isRequired,

  handlePremiseNameChange: PropTypes.func.isRequired,
  handleNumberOfSwicthesChange: PropTypes.func.isRequired,
  handleNetworkOperatingSystemChange: PropTypes.func.isRequired,
  handleOtherNetworkElementsChange: PropTypes.func.isRequired,

  hypervisorOptions: PropTypes.array.isRequired,
  seatOptions: PropTypes.array.isRequired,
  gpuOptions: PropTypes.array.isRequired,
  remotingOptions: PropTypes.array.isRequired,
  primaryAppOptions: PropTypes.array.isRequired,
  handleServerChange: PropTypes.func.isRequired,
  handleOtherValChange:PropTypes.func.isRequired,
  handleHypervisorChange: PropTypes.func.isRequired,
  handleSeatsChange: PropTypes.func.isRequired,
  handleGpuChange: PropTypes.func.isRequired,
  handleRemotingChange: PropTypes.func.isRequired,
  handlePrimaryAppChange: PropTypes.func.isRequired,
 // handleBoxDeployment: PropTypes.func.isRequired,
  handleBoxxNvidiaGPUChange: PropTypes.func.isRequired,
  handleBoxDeploymentTypeChange: PropTypes.func.isRequired,
  handleCertfiiedSystemChange: PropTypes.func.isRequired,
  
  boxxDeploymentTypeOptions :PropTypes.array.isRequired,
  //boxxDeploymentOptions:PropTypes.array.isRequired,
  boxxCertifiedSystemOptions:PropTypes.array.isRequired,
  boxxNvidiaGPUOptions:PropTypes.array.isRequired,





};

export default Environment;
