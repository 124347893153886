import React from "react";
import SectionHeader from "./SectionHeader";
import Input from "../util/Input";
import Select from "../util/Select";
import { PropTypes } from "prop-types";

const ContainerRegistry = props => {
 
  return (
    !props.isRWA04 && 
        <div className='AppSection'>
    
      {props.isFleeting &&(<SectionHeader title={"NGC Administrator"} />)}   
     {!props.isFleeting &&(<SectionHeader title={"NGC Administrator"} />)}   
      <p className={"leftMargin"}>
        <small>
        The NGC administrator is responsible for managing users and their permissions.<br></br>
         
        </small>
      </p>
      <div className={"leftColumn"}>
        <Select
          labelText={"Use Primary Contact Above"}
          optionValues={props.adminOptions}
          value={props.adminOption}
          onChange={props.handleAdminChange}
        />
        <Input
          labelText={"Email Address"}
          isRequired={true}
          readOnly={props.lockContainer}
          value={props.emailAddress}
          onChange={props.handleEmailAddressChange}
          maximumLength={200}
        />
        <Input
          labelText={"First Name"}
          isRequired={true}
          readOnly={props.lockContainer}
          value={props.firstName}
          onChange={props.handleFirstNameChange}
          maximumLength={50}
        />
        <Input
          labelText={"Last Name"}
          isRequired={true}
          readOnly={props.lockContainer}
          value={props.lastName}
          onChange={props.handleLastNameChange}
          maximumLength={50}
        />
 

      </div>

    </div>
    
  );
  
};

ContainerRegistry.propTypes = {
  isFleeting:PropTypes.bool.isRequired,
  adminOptions: PropTypes.array.isRequired,
  adminOption: PropTypes.number,
  handleAdminChange: PropTypes.func.isRequired,
  lockContainer: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  //shortCompanyName: PropTypes.string.isRequired,
  handleEmailAddressChange: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
 // handleShortCompanyNameChange: PropTypes.func.isRequired
};

export default ContainerRegistry;
