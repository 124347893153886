import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ProductInfo from '../presentational/ProductFamilyInfo/ProductInfo';


function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    //onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '50%', maxHeight: 600 } }}
      maxWidth="xm"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      
      <DialogTitle>
      {props.title}
      </DialogTitle>
      <DialogContent dividers>
      <ProductInfo   
      BoxxProductFamily={props.BoxxProductFamily}
      PartnerURLVal={props.PartnerURLVal}
        link={props.link}
          linktext={props.linktext             
           }
           idmlogin={props.idmlogin}
         isrwa03={props.isrwa03} 
         isrwa04={props.isrwa04} 
         title={props.title}
           productfamily={props.productfamily}
           ></ProductInfo>
      
      </DialogContent>
      <DialogActions>
        { <Button  onClick={props.handleCancelClick}>
          Cancel
        </Button> }
      
        { <Button className={"acceptbtn"} onClick={handleOk}>Agree</Button> }
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  
  BoxxProductFamily: PropTypes.string.isRequired,
  PartnerURLVal: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
   productfamily: PropTypes.string.isRequired, 
  title: PropTypes.string.isRequired,
  isrwa03: PropTypes.number.isRequired,
  isrwa04: PropTypes.number.isRequired,
   idmlogin: PropTypes.string.isRequired,
   linktext: PropTypes.string.isRequired,
   link: PropTypes.string.isRequired,
};

export default function ConfirmationDialog(props) {
    
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState('');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      
        <ConfirmationDialogRaw
         BoxxProductFamily={props.BoxxProductFamily}
         PartnerURLVal={props.PartnerURLVal}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          productfamily={props.productfamily}
          title={props.title}
          handleCancelClick={props.handleCancelClickEvent}
          isrwa03={props.isrwa03}
          isrwa04={props.isrwa04}
           link={
           props.link
              
          }
          linktext={
             props.linktext
              
          }
          idmlogin={props.idmlogin} 
        />
     
    </Box>
  );
}
ConfirmationDialog.propTypes = {
  handleCancelClickEvent: PropTypes.func.isRequired,
  BoxxProductFamily: PropTypes.string.isRequired,
  PartnerURLVal: PropTypes.string.isRequired,
  
    productfamily: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isrwa03: PropTypes.number.isRequired,
    isrwa04: PropTypes.number.isRequired,
     idmlogin: PropTypes.string.isRequired,
     linktext: PropTypes.string.isRequired,
     link: PropTypes.string.isRequired,
  };
  
